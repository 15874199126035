import React, {Component} from "react"
import avatar from "../assets/img/avatar.png";
import Tada from 'react-reveal/Tada';
import {Fade} from "react-reveal";
import Clouds from "./Clouds"
import {Spring} from "react-spring/renderprops-universal";
import {hideAll} from "react-reveal/src/lib/globals";



class Banner extends Component {

    constructor(props) {
        super(props)
        this.state = {
            hovered: false,
            clicked: false,
            height: "7vh",
            width: "20vw",
            showForm: false
        }
    }

    sayHi = () => {
        if (!this.state.clicked) {
            this.setState({
                clicked: true,
                height: "50vh",
                width: "30vw",
                obj: this.contactForm,
                showForm: true
            })
        } else {
            this.setState({
                clicked: false,
                showForm: true
            })
        }
        console.log("Pressed")
    }

    render() {
        return (
            <div className="banner">
                <h1 className="name">
                    Simon Edvardsson
                </h1>
                <Clouds/>
                <h1 className={"bannerHeader"}>
                    Student of Computer Science
                </h1>
                <p className="bannerInfo">
                    Any fool can write code that a computer can understand.<br/> Good programmers write code that humans
                    can understand
                </p>
                <Tada>
                    <img className={"avatar"}
                         src={avatar}
                         alt={"Simon avatar"}/>
                </Tada>
                <div className="mountain">
                    {svgMountain}
                </div>
            </div>
        );
    }

    contactForm = () => {
        return(
            <div style={{height: this.state.height, width: this.state.width}} className="sayHi" onClick={this.sayHi}>
                <form method="POST">
                    <label htmlFor="name">Name</label>
                    <input type="text" name="name"/>

                    <label htmlFor="email">Email</label>
                    <input type="email" name="email"/>

                    <label htmlFor="message">Message</label>
                    <textarea name="message" rows="3"></textarea>

                    <input type="submit"/>
                </form>
            </div>
        )
    }


    sayHiDiv = () => {
        return(
            "Say hi!"
        )
    }
}
const svgMountain =
    <svg x="0px" y="0px" viewBox="0 140 648 140">
        <path d="M453.9,169.9c-4-0.2-7.8-0.9-11.8-1c-3.3,0-6.8,0.4-9.9,1.6c-10.8,4.2-21.5,8.6-32.1,13.3c-7.8,3.5-15.3,7.5-23,11.4
                c-6.4,3.2-12.7,5.1-19.6,1.2c-2.1-1.2-4.3-0.8-5.9,1.6c-1.8,2.6-3.5,5.5-7.6,3.5c-0.4-0.2-2.2,1-2.1,1.3c0.2,1.2,0.6,2.6,1.5,3.4
                c9,7.5,19.8,11.3,30.8,14.6c3.4,1,6.7,2.4,10.3,3.7c-2.7,5.5,2.4,9.6,2.3,15.4c-3.5-3-6.5-5.8-9.8-8.3c-1.7-1.3-3.7-2.2-5.6-2.9
                c-4.9-1.8-10-3.2-15-5c-5.6-2.1-11-5.4-17.4-4.1c-0.6,0.1-1.4-0.5-2-1c-4.2-3.2-8.3-6.5-12.5-9.7c-1.4-1.1-2.9-1.9-4.7-3.1
                c-0.5,5.1,2.8,7.5,5.2,10.3c2.9,3.3,5.5,6.9,8.8,9.7c4.6,4,9.7,7.5,14.6,11.3c2.5,2,4.9,4.1,7.4,6.1c-0.1,0.3-0.3,0.7-0.4,1
                c-1-0.1-2.2,0.1-3-0.3c-11.8-5.5-23.6-11.1-35.4-16.7c-1.5-0.7-2-1.8-1.9-3.7c0.2-3.3-0.5-5.2-3-7.6c-0.5-0.5-1-1-1.7-1.3
                c-1.5-0.7-3.5,0.1-4.2,1.6c0.7-1.5,1.1-3.2,0.9-4.8c-1.5-1.1-3.7-0.9-5,0.3c-0.9,0.8-1.5,2.1-2.6,2.4c-0.8,0.2-1.7-0.2-2.6-0.2
                c-2.1,0-3.3,2.2-4.7,3.8c-1.6,1.8-3.9,3.1-6.3,3.4c1.7-2.6,3-5.4,4-8.3c0.5-1.7,1-3.5,0.5-5.2c-0.4-1.7-2-3.2-3.8-3
                c-0.6,0.1-1.1,0.3-1.7,0c-0.7-0.3-0.8-1.4-0.5-2.1s1-1.2,1.6-1.9c2.2-2.7,1.3-6.7,0.3-10c-3.6,0.2-8,1.9-11.5,2.8
                c-2,0.5-2.7-0.3-3.1-1.9c-0.5-2.3-0.7-4.7-1.3-7c-0.4-1.5-0.9-3.1-1.8-4.2c-1-1.2-2.3-1.5-3.6,0.2c-2.9,3.7-6,7.4-9.2,10.9
                c-0.7,0.8-2.4,1.3-3.3,1c-3.7-1.2-7.5-2.6-11-4.4c-3.3-1.6-6.5-1.9-10.1-0.9c-1.1,0.3-2.5-0.6-3.8-0.9c2.8-7.4,11.7-6.6,15.1-13
                c-1.9-1.5-3.8-3-5.8-4.4c-2.1-1.5-3.2-3.2-3.1-6.1c0.3-7-0.3-7.6-7.1-6.9c-7.3,0.7-14.8,1.2-21.7,3.2c-6.9,2-18.4,6.7-23,12.4
                c-1.8,2.2-3.1,4.9-5.4,6.4c-1.5,1-3.4,1.4-5.1,2c-5.5,2-10.1,6.2-12.5,11.5c-0.6,1.4-1.2,2.9-2.6,3.5c-1,0.4-2.1,0.2-3.2,0.2
                c-4.3-0.1-8,2.9-11.6,5.3c-15.7,10.4-33.5,7-50.3,12.5c5.5,2.3,10,4.5,14.6,6.1c3,1.1,6.4,1.1,9.5,2.1c3.3,1.1,6.3,2.9,9.4,5.2
                c-3.6,0.4-7.1,0.8-10.7,1c-3.7,0.3-7,1.4-9.8,4c-0.6,0.6-2,1-2.7,0.7c-6.4-2.7-16.1-8.4-23.2-6.9c1.3-0.3,2.6-0.8,3.7-1.6
                c-8.5-2.8-17.5-4-26.4-3.5c1.9-1.2,3.7-2.3,5.6-3.5c0.4-0.2,0.8-0.5,0.9-0.9c0.4-1.1-1.2-1.8-2.2-2.4c-1.4-0.7-2.3-2.2-3.4-3.3
                c-7.1-7-20.8,10.5-24.7,15.2c-0.6,0.7-1.2,1.5-1.4,2.5c-0.2,0.9,0.2,2.1,1.1,2.4c-8.6-1.4-17.8,0.6-24.9,5.6
                c0.6-3.6,4.3-5.6,7.3-7.6c3-2,5.6-4.4,7.9-7.1c1.6-1.9,3.1-4.1,5.3-5.4c1.4-0.8,3-1.3,4.4-1.9c9-3.8,19-21.1,30-15.5
                c6.5,3.3,12.9,7,19.8,9.2c4.2,1.3,9.2,0.5,13.7,0.2c11-0.9,19.6-7.4,28.7-12.6c2.1-1.2,4.6-1.8,6.9-2.7
                c21.6-8.7,41.6-20.5,61.2-32.8c4.8-3,10-3.6,15.7-4c5.7-0.4,11.9-0.1,17.1-3.6c2.5-1.7,5.4-2.9,8-4.4c3.4-2,6.8-2.3,10.2,0
                c7.2,5,14.3,10.1,21.6,15.1c4.9,3.4,10,6.2,16.4,6.3c7.5,0.2,14.7,1.3,20.4,7.3c1.9,2,5.6,2.4,8.5,3.5c0.8,0.3,1.8,0.4,2.5,0.9
                c4.2,2.9,8.3,6.1,12.5,8.9c1.6,1.1,3.4,1.7,5.2,2.4c2.4,0.9,4.9,1.9,7.4,2.2c2.7,0.3,5.6,0.2,8.1-0.7c4.3-1.5,8.5-2.9,13.1-1.4
                c1,0.3,2.2-0.2,3.3-0.1c1.3,0,2.8-0.1,3.7,0.5c2.1,1.4,4.2,1.4,6,0c9.5-7.2,20.8-9.9,31.7-13.7c6.6-2.3,12.8-5.4,19.3-8
                c5.4-2.2,10.9-4.2,16.3-6.5c3.1-1.3,5.8-3.4,8.8-4.8c2.2-1,4.7-2.3,7-2.2c2.2,0.1,5.3,1.3,6.2,2.9c2.7,5,6.8,8.2,11.8,10.2
                c7.6,3,14,7.5,19.3,13.8c0.6,0.8,1.4,1.5,2.3,2c8.2,4.5,16.4,9.1,24.6,13.6c6.6,3.6,13.6,9.3,21.4,6.3c3.8-1.5,7-4.2,10.1-6.8
                c1.4-1.2,3-2.3,4.8-2.3c1.5,0,2.9,0.8,4.2,1.6c14.4,8.9,28.9,20.2,44.7,26.3c3.9,1.5,6.8,3.5,9.2,7.3c2.2,3.5,5.8,6.2,9.2,8.7
                c6.6,4.8,13.6,9.2,20.3,13.9c1.2,0.9,1.7,2.8,2.9,5c-1.7-0.2-2.7-0.1-3.1-0.5c-5.2-4.3-33.9-25.7-39.4-17.7c1.4-2,2.8-4.5,1.6-6.6
                c-0.8-1.5-2.7-2.1-4.3-2c-1.7,0.1-3.2,0.9-4.7,1.7c-1.7,0.9-3.5,1.8-5.2,2.6c0.6-1.2,1.2-2.4,1.2-3.7s-0.8-2.7-2.1-2.9
                c0.2-1.2,0.4-2.4,0.6-3.5c-1.6,0.5-3.2,0.9-4.7,1.4c-1.7,0.5-18.5,6.9-18.7,4.5c-0.2-2,3-2.5,3.7-4.3c0.2-0.6,0.1-1.3,0.3-1.9
                c0.3-1.2,1.3-2.1,2.4-2.9c1-0.7,2.2-1.5,2.7-2.6c0.9-1.8-0.7-3.7-2.3-4.4c1.6-6.6-15.8-2.7-19.1-1.7c-6.9,2.1-14,1.4-20.7-1.7
                c-4.1-2-7.9-1.7-11.7,0.8c-3.2,2.1-6.8,4-10.3,5.5c-3.6,1.6-7,0.9-10.4-1.2c-5.1-3.1-9.9-1.2-14.8,1.9c2,6.3,2.3,13.3,8.2,17.9
                c0.7,0.5,1,1.8,1,2.7c0.4,6.4,3.6,11.2,8.5,15.1c1.7,1.3,2.4,2.8,0.7,5c-5-2.8-32.6-23-37.8-13.4c3.1-5.7-1.7-12.4-1.8-18.9
                c0-2.3,0.5-4.5,1.1-6.7c-4.3-2-9.3,1.3-12.2,5c-0.6,0.8-1.6,1.7-2.5,1.3c-1.6-0.7,0-3,0.7-4.6c1.9-4.4-5.8-9-3.6-13.3
                c0.6-1.2,1.9-2,2.3-3.2c0.3-0.8,0.2-1.6,0.2-2.5c0.3-5.1,5.1-8.5,9.4-11.3c-4.3,2.7-11.2,2.7-16,2.1c1.1-0.2,1.6-1.5,1.6-2.6
                s-0.1-2.3,0.5-3.2c1.2-1.6,4.1-0.7,5.6-2.1c0.8-0.7,0.9-1.9,1.4-2.8c2.1-3.8,8.6-2.7,10.8-6.4c-2.7-0.4-5-2.6-5.3-5.3
                c-0.1-0.8-0.1-1.7-0.6-2.2c-0.5-0.6-1.4-0.6-2.2-0.6C455.6,170,454.8,169.9,453.9,169.9z"/>
    </svg>

export default Banner;